<template>
  <div class=" person-warp container flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img
        class="img-title"
        :src="require('@/assets/image/personInfo.png')"
        alt=""
      />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/path.png')"
            class="img-icon"
          /><span>人员详情</span>
        </div>
      </div>
    </div>
    <div class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">人员姓名</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.name }}
          </div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">证件号码</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.cid }}
          </div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block">电话号码</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.phone }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">出生日期</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.birthday }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">年龄</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.age }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">民族</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_nation',forms.nation) }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">籍贯</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.native }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">政治面貌</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_politics', forms.politics )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">文化程度</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{  $dt.changeCodeName('people_edu', forms.edu )}}
          </div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">婚姻状况</div>
          <div class="panel-item-right bra3 plr panel-item-block">xxxx</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">户型类型</div>
          <div class="panel-item-right bra3 plr panel-item-block">xxxx</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">人口类型</div>
          <div class="panel-item-right bra3 plr panel-item-block">xxxx</div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block">管理类别</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_mtype', forms.mtype )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">兵役状态</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_mss', forms.mss )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">计生对象</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_fps', forms.fps )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">优抚对象</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_care', forms.care )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">健康状况</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_health', forms.health )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">宗教人士</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_faith', forms.faith )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">养老保险</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_eins', forms.eins )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">医疗保险</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_mins', forms.mins )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">贫困状态</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.changeCodeName('people_poverty', forms.poverty )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            现居地址
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.addr }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">户主姓名</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.hh }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">与户主关系</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            
            {{ $dt.changeCodeName('people_rwh', forms.rwh )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">车辆信息</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.car }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">工作单位</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.unit }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">单位编号</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.unit_id }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">一级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaOne}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">二级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaTwo}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">三级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaThree}}</div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">门牌号</div>
          <div class="panel-item-right bra3 plr panel-item-block">XXX</div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block">迁入时间</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{$dt.formatDate(forms.in_at)}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">迁出时间</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{$dt.formatDate(forms.out_at)}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">原因</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.ioinfo}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">照片</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            <img
               v-for="(item,index) in forms.photos"
              :key="index"
              :src="item"
              class="panel-img-square"
              alt=""
              @click="handlePreImg(forms.photos,index)"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            备注信息
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ forms.remark }}
          </div>
        </li>
      </ul>
      <van-image-preview v-model="show" closeable :images="preImages" :start-position="startIndex"> 
      </van-image-preview>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/people/get",
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="less">
.person-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
}
</style>
